<p-growl [(value)]="growl_msgs"></p-growl>




<div class="container-fluid bglogincolor">
  <div class="row justify-content-center m-auto mh-height ">

    <div class="col-md-4 col-12 m-auto bx-shadow pb-1">

      <div class="row justify-content-center d-flex p-4">
        <div class=" col-12 text-center mb-4">
          <img style="width: 46%;" src="assets/images/logo.png" />
        </div>
        <div class="col-6"></div>
        <div class="sub-text-website font-9 col-6 col-md-6  text-right m-auto">
          <span>Manufacturer</span>
        </div>
        <div class="col-12 pt-4">
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
            <!-- <div class="form-group">
              <input type="text" placeholder="User Name" formControlName="username" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">User Name is required</div>
              </div>
            </div> -->
            <div class="form-group">
              <input type="text" placeholder="First Name" formControlName="first_name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" (keydown.space)="$event.preventDefault();" />
              <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                <div *ngIf="f.first_name.errors.required">First Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Last Name" formControlName="last_name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" (keydown.space)="$event.preventDefault();" />
              <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                <div *ngIf="f.last_name.errors.required">Last Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Email" formControlName="email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (keydown.space)="$event.preventDefault();" />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
              </div>
            </div>
            <div class="form-group">
              <input formControlName="mobile" placeholder="Mobile" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" (keydown.space)="$event.preventDefault();" />
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                <div *ngIf="f.mobile.errors.required">mobile is required</div>
                <div *ngIf="f.mobile.errors.minlength">mobile number should be 10 digits</div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" placeholder="Company Name" formControlName="company_name" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" (keydown.space)="$event.preventDefault();" />
              <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                <div *ngIf="f.company_name.errors.required">company Name is required</div>
              </div>
            </div>
            <div class="form-group">
              <button class="font-11 text-center loginButton w-100 rounded-lg">
                <i class="fa fa-spinner fa-spin" *ngIf="inline_loader"></i> Signup</button>
            </div>
          </form>

        </div>

        <div class="col-12 pt-4 text-center">
          <a class="signuplink" routerLink="../home" routerLinkActive="activeRoute">Existing User? Log In</a>
        </div>


      </div>
    </div>

  </div>
</div>