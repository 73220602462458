
<p-growl [(value)]="growl_msgs"></p-growl>
 
<div class="container-fluid bglogincolor">
  <div class="row justify-content-center m-auto mh-height ">

<div class="col-md-10 col-12 m-auto bx-shadow pb-1">

    
  <div class="row justify-content-center d-flex p-4">
      <div class=" col-12 text-center mb-1">
          <h4>Update Profile</h4>
      </div>
   
      <div class="sub-text-website font-9 col-6 col-md-12 text-right m-auto">
          <span>Manufacturer</span>
        </div>
    
  
         <div class="col-12 pt-4">
        
                <form [formGroup]="registerForm" (ngSubmit)="onSubmit(registerForm.value)">
                  <div class="row">
                  <!-- <div class="form-group col-12 col-md-6"> 
                    <input type="text" placeholder="User Name" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">User Name is required</div>
                    </div>
                </div>  -->
                  <div class="form-group col-12 col-md-6"> 
                        <input type="text" placeholder="First Name" (keydown.space)="$event.preventDefault();" formControlName="first_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }" />
                        <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name.errors.required">First Name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-12 col-md-6"> 
                        <input type="text" placeholder="Last Name" (keydown.space)="$event.preventDefault();" formControlName="last_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" />
                        <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                            <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                        </div>
                    </div>
                    <div class="form-group col-12 col-md-6"> 
                        <input type="text" placeholder="Email" readonly formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                    <!-- <div class="form-group col-12 col-md-6"> 
                        <input type="text" placeholder="Alt Email" formControlName="email_2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email_2.errors }" />
                        <div *ngIf="submitted && f.email_2.errors" class="invalid-feedback">
                            <div *ngIf="f.email_2.errors.required">Email is required</div>
                            <div *ngIf="f.email_2.errors.email">Email must be a valid email address</div>
                        </div>
                    </div> -->
                    <div class="form-group col-12 col-md-6"> 
                        <input  formControlName="mobile" readonly (keydown.space)="$event.preventDefault();" placeholder="Mobile" class="form-control" (keypress)="keyPress($event)" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                        <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                            <div *ngIf="f.mobile.errors.required">mobile is required</div>
                            <div *ngIf="f.mobile.errors.minlength">mobile number should be 10 digits</div>
                        </div>
                    </div>
                    <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="Company Name" formControlName="company_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                      <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                          <div *ngIf="f.company_name.errors.required">company Name is required</div>
                      </div>
                  </div> 
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="Brand Name" formControlName="brand_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.brand_name.errors }" />
                      <div *ngIf="submitted && f.brand_name.errors" class="invalid-feedback">
                          <div *ngIf="f.brand_name.errors.required">brand Name is required</div>
                      </div>
                  </div> 
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="PAN Number" (keydown.space)="$event.preventDefault();" formControlName="pan_no" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pan_no.errors }" />
                      <div *ngIf="submitted && f.pan_no.errors" class="invalid-feedback">
                          <div *ngIf="f.pan_no.errors.required">pan number is required</div>
                      </div>
                  </div> 
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="GST Number" (keydown.space)="$event.preventDefault();" formControlName="gst_No" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.gst_No.errors }" />
                      <div *ngIf="submitted && f.gst_No.errors" class="invalid-feedback">
                          <div *ngIf="f.gst_No.errors.required">gst number is required</div>
                      </div>
                  </div> 
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="Address" formControlName="address" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                      <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                          <div *ngIf="f.address.errors.required">address is required</div>
                      </div>
                  </div> 
                 
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="Pin Code" (keydown.space)="$event.preventDefault();" (keypress)="keypinPress($event)" minlength=6  maxlength=6 formControlName="pin_code" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }" />
                      <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                          <div *ngIf="f.pin_code.errors.required">pin_code number is required</div>
                      </div>
                  </div>
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="Phone Number" (keydown.space)="$event.preventDefault();" (keypress)="keyPress($event)" minlength=10  maxlength=10 formControlName="phone_no" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone_no.errors }" />
                      <div *ngIf="submitted && f.phone_no.errors" class="invalid-feedback">
                          <div *ngIf="f.phone_no.errors.required">phone_no number is required</div>
                      </div>
                  </div>  
                  <div class="form-group col-12 col-md-6"> 
                      <input type="text" placeholder="Helpline Number" (keydown.space)="$event.preventDefault();" (keypress)="keyPress($event)" minlength=14  maxlength=14 formControlName="helpline" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.helpline.errors }" />
                      <div *ngIf="submitted && f.helpline.errors" class="invalid-feedback">
                          <div *ngIf="f.helpline.errors.required">helpline number is required</div>
                      </div>
                  </div> 
                  <div class="form-group col-12 col-md-6 oemAutoComplete"> 
                      <p-autoComplete placeholder="City" formControlName="city" [suggestions]="city_list" field="name"  
                      (completeMethod)="filterCity($event.query)"  (onSelect)="onSelectCity($event)"
                          [minLength]="1" [style]="{'width':'100%'}"></p-autoComplete>
                      
                  </div> 
                  <div class="form-group col-12 col-md-6"> 
                      <p-dropdown  [options]="work_dd" formControlName="main_category"
                      (onChange)="onChangeWork($event.value)" [style]="{'width':'100%'}"></p-dropdown>
                      <!-- <input type="text" placeholder="Main Category"   formControlName="main_category" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.main_category.errors }" />
                      <div *ngIf="submitted && f.main_category.errors" class="invalid-feedback">
                          <div *ngIf="f.main_category.errors.required">main_category number is required</div>
                      </div> -->
                  </div> 
                  <div class="form-group col-12 col-md-6"> 
                      <p-multiSelect class="oemMultiSelect" [options]="category_dd" formControlName="sub_category"
                                [style]="{'width':'100%'}"></p-multiSelect>
                      <!-- <input type="text" placeholder="Sub Category"  formControlName="sub_category" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.sub_category.errors }" />
                      <div *ngIf="submitted && f.sub_category.errors" class="invalid-feedback">
                          <div *ngIf="f.sub_category.errors.required">main_category number is required</div>
                      </div> -->
                  </div>  
                  <div class="form-group col-12 col-md-6">
                    <div class="formHead">
                        Logo
                    </div>
                    <div> 
                        <div>
                            <p-fileUpload styleClass="productFileUpload" name="dealerImage[]" accept="image/*"
                                maxFileSize="1000000" #oemImg [multiple]="true" chooseLabel="Choose" [previewWidth]="100"
                                [customUpload]="true" [showUploadButton]="false" (onClear)="onClear($event)"
                                (onRemove)="onRemove($event)" (onSelect)="onSelect($event)"></p-fileUpload>
                        </div>
                        <div class="imageContainer">
                            <div fxLayout="row" fxLayoutAlign="space-between center"
                                *ngFor="let il of dealer_data?.showroom_pic_list" style="padding:20px;">
                                <img src="{{il.showroom_pic}}" style="width:100px;margin-right:30px">
                                <button (click)="removeImage(il.id)" style="background:transparent;border:0"><i
                                        class="fa fa-times" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="form-group col-12 col-md-6">
                      <button class="font-11 text-center loginButton w-100 rounded-lg mt-0">
                        <i class="fa fa-spinner fa-spin" *ngIf="inline_loader"></i> Submit</button>
                    </div>

                    
                    </div>
                   
                </form>
          
         </div>

         <div class="col-12 pt-4 text-center">
          <a class="signuplink" routerLink="../../home" routerLinkActive="activeRoute">Existing User? Log In</a>
        </div>
         
        
  </div>
  </div>

  </div>
  </div>
 